.account{
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
}

.wrapper{
    min-width: 50%;
    max-width: 80%;
}

.reLogin{
    position:fixed;
    padding:0;
    margin:0;
    border-radius: 2rem;
    top:0;
    left:0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, rgba(0,0,0,0.8), rgba(0,0,0,0.8));
}