.dashboard{
    flex:1;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-evenly;
    text-align: center;
    background: linear-gradient(to right bottom, rgba(255,255,255, 0.8), rgba(255, 255, 255, 0.2));
    border-radius: 2rem;
    max-height: 100%;
    max-width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 20px;
}

.option{
    display: flex;
    margin: 2rem 0rem;
    padding: 1rem 5rem;
    align-items: center;
}

.option h2{
    padding: 0rem 2rem;
}

.option:hover{
    cursor: pointer;
}

@media only screen and (min-width: 1100px)
{
    .icon{
        max-width: 2vw;
        min-width: 2vw;
    }
}

@media only screen and (max-width: 1099px)
{
    .icon{
        max-height: 5vh;
    }
}