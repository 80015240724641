.element{
  color: black;
}

.botti{
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    
}
.italiano{
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.main{
    font-size: 2rem;
    text-align: left;
    color: black;
    background: linear-gradient(to right bottom, rgba(255,255,255,0.5), rgba(255,255,255,0.2));
}