@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap);
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
::selection{
  background: #4158d0;
  color: #fff;
}
.wrapper{
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 380px;
  background:linear-gradient(to right bottom, rgba(255,255,255,0.9), rgba(255,255,255,0.4));
  border-radius: 15px;
  box-shadow: 0px 15px 20px rgba(0,0,0,0.1);
}
.wrapper .title{
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  line-height: 100px;
  color: #fff;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 15px 15px 0 0;
  /*background: linear-gradient(-135deg, #c850c0, #4158d0);*/
  background: linear-gradient(to right bottom, rgba(282, 93, 4, 1), rgba(255,186,8,0.7));
}
.wrapper form{
  padding: 10px 30px 50px 30px;
}
.wrapper form .field{
  height: 50px;
  width: 100%;
  margin-top: 20px;
  position: relative;
}
.wrapper form .field input{
  height: 100%;
  width: 100%;
  outline: none;
  font-size: 17px;
  padding-left: 20px;
  border: 1px solid lightgrey;
  border-radius: 25px;
  transition: all 0.3s ease;
}
.wrapper form .field input:focus,
form .field input:valid{
  border-color: #e85d04;
}
.wrapper form .field label{
  position: absolute;
  top: 50%;
  left: 20px;
  color: #999999;
  font-weight: 400;
  font-size: 17px;
  pointer-events: none;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  transition: all 0.3s ease;
}
form .field input:focus ~ label,
form .field input:valid ~ label{
  top: 0%;
  font-size: 16px;
  color: #e85d04;
  background: linear-gradient(to top, rgba(255,255,255, 0) 0%,rgba(255,255,255, 1) 50%, rgba(255,255,255, 0)100%);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
form .content{
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 50px;
  font-size: 16px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}
form .content .checkbox{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
form .content input{
  width: 15px;
  height: 15px;
  background: red;
}
form .content label{
  color: #262626;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-left: 5px;
}
form .content .pass-link{
  color: "";
}
form .field input[type="button"]{
  color: #fff;
  border: none;
  padding-left: 0;
  margin-top: -10px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  background: linear-gradient(to right bottom, rgba(282, 93, 4, 1), rgba(255,186,8,0.7));
  transition: all 0.3s ease;
}
form .field input[type="button"]:active{
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}
form .signup-link{
  color: #262626;
  margin-top: 20px;
  text-align: center;
}
form .pass-link a,
form .signup-link a{
  color: #e85d04;
  text-decoration: none;
}
form .pass-link a:hover,
form .signup-link a:hover{
  text-decoration: underline;
}

.login-container{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  justify-items: center;
}

.login-container .wrapper{
  max-width: 100%;
}
.account{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: center;
            align-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
}

.wrapper{
    min-width: 50%;
    max-width: 80%;
}

.reLogin{
    position:fixed;
    padding:0;
    margin:0;
    border-radius: 2rem;
    top:0;
    left:0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, rgba(0,0,0,0.8), rgba(0,0,0,0.8));
}

.glass{
    background: linear-gradient(to right bottom, rgba(255,255,255, 0.7), rgba(255,255,255,0.3));
    border-radius: 2rem;
    z-index: 2;
    -webkit-backdrop-filter: blur(0.5rem);
            backdrop-filter: blur(0.5rem);
    display: -webkit-flex;
    display: flex;
 }

@media only screen and (min-width: 1100px)
{
 .glass{
    min-height: 80vh;
    min-width: 50vw;
    max-height: 80vh;
    max-width: 80vw;
 }
 .main-content{
     padding: 0 15px 0 15px;
     max-height: 100%;
 }
}

@media only screen and (max-width: 1099px)
{
 .glass{
    min-height: 80vh;
    min-width: 80vw;
    max-height: 100vh;
 }

 .card-columns{
    max-height: 90%;
}

.main-content{
    padding-top: 10vh;
    max-height: 100%;
    max-width: 100%;
    overflow-x: hidden;
}
}



.content{
    -webkit-flex: 3 1;
            flex: 3 1;
    display: -webkit-flex;
    display: flex;
    border-radius: 2rem;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: center;
            align-content: center;
    overflow-y: auto;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.circle1, .circle2{
    background: linear-gradient(to right bottom, rgba(255,255,255, 0.8), rgba(255, 255, 255, 0.2));
    height: 20rem;
    width: 20rem;
    position: absolute;
    border-radius: 50%;
    z-index: -1;
}


.circle1{
    top: 5%;
    right: 17%;
}

.circle2{
    bottom: 5%;
    left: 20%
}
.dashboard{
    -webkit-flex:1 1;
            flex:1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-content: center;
            align-content: center;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    text-align: center;
    background: linear-gradient(to right bottom, rgba(255,255,255, 0.8), rgba(255, 255, 255, 0.2));
    border-radius: 2rem;
    max-height: 100%;
    max-width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 20px;
}

.option{
    display: -webkit-flex;
    display: flex;
    margin: 2rem 0rem;
    padding: 1rem 5rem;
    -webkit-align-items: center;
            align-items: center;
}

.option h2{
    padding: 0rem 2rem;
}

.option:hover{
    cursor: pointer;
}

@media only screen and (min-width: 1100px)
{
    .icon{
        max-width: 2vw;
        min-width: 2vw;
    }
}

@media only screen and (max-width: 1099px)
{
    .icon{
        max-height: 5vh;
    }
}

.gallery{
  overflow-y: scroll;
  -webkit-column-count: 3;
  column-count: 3;

  -webkit-column-width: 33%;
  column-width: 33%;

  padding: 0 12px;
}

.gallery .pics{
  transition: all 350ms ease;

  cursor: pointer;

  margin-bottom: 12px;
}

.gallery .pics:hover{
  -webkit-filter: opacity(.8);
          filter: opacity(.8);
}

@media (max-width: 991px){
  .gallery{
    -webkit-column-count: 2;
    column-count: 2;
  }
}

@media (max-width: 480px){
  .gallery{
    -webkit-column-count: 1;
    column-count: 1;
  
    -webkit-column-width: 100%;
    column-width: 100%;
  
  }
}

.model{
  width: 100%;
  height: 100vh;
  position: fixed;
  top:0;
  left:0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: #000000;
  transition: opacity .4s ease, visibility .4s ease, transfrom .5s ease-in-out ;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
  overflow: hidden;
  z-index: 999;
}

.model.open{
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.model img{
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin: 0 auto;
  border: 5px;
}

.model.open svg{
  position: fixed;
  top: 10px;
  right: 10px;
  height: 2rem;
  width: 2rem;
  padding: 5px;
  background-color: rgba(0,0 0, 0.4);
  color: #ffffff;
  cursor: pointer;
}
.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #000;
    -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    -webkit-animation: lds-ellipsis1 0.6s infinite;
            animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    -webkit-animation: lds-ellipsis3 0.6s infinite;
            animation: lds-ellipsis3 0.6s infinite;
  }
  @-webkit-keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @-webkit-keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @-webkit-keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(24px, 0);
              transform: translate(24px, 0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(24px, 0);
              transform: translate(24px, 0);
    }
  }
.element{
  color: black;
}

.botti{
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    
}
.italiano{
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.main{
    font-size: 2rem;
    text-align: left;
    color: black;
    background: linear-gradient(to right bottom, rgba(255,255,255,0.5), rgba(255,255,255,0.2));
}
.topmenu{
    background: linear-gradient(to right bottom, rgba(255,255,255, 1), rgba(255, 255, 255, 0.6));
    border-radius: 2rem 2rem 0 0;
    width: 100%;
    min-height: 10%;
    position: fixed;
    top: 0;
    left: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 10px;
}

.topmenu img{
    max-height: 5vh;
    padding-left: 10px;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.night{
    background: linear-gradient(to right top, #141852, #070B34);
}

.day{
    background: linear-gradient(to right top, #65dfc9, #6cdbeb);
}

.sunset{
    background: linear-gradient(to right top, #855988, #6B4984, #483475, #2B2F77, #141852, #070B34);
}

.sunrise{
    background: linear-gradient(to left bottom,#070B34,#2B2F77, #483475, #6B4984,#855988, #F1B351, #FDE050)
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

