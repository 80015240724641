
.glass{
    background: linear-gradient(to right bottom, rgba(255,255,255, 0.7), rgba(255,255,255,0.3));
    border-radius: 2rem;
    z-index: 2;
    backdrop-filter: blur(0.5rem);
    display: flex;
 }

@media only screen and (min-width: 1100px)
{
 .glass{
    min-height: 80vh;
    min-width: 50vw;
    max-height: 80vh;
    max-width: 80vw;
 }
 .main-content{
     padding: 0 15px 0 15px;
     max-height: 100%;
 }
}

@media only screen and (max-width: 1099px)
{
 .glass{
    min-height: 80vh;
    min-width: 80vw;
    max-height: 100vh;
 }

 .card-columns{
    max-height: 90%;
}

.main-content{
    padding-top: 10vh;
    max-height: 100%;
    max-width: 100%;
    overflow-x: hidden;
}
}



.content{
    flex: 3;
    display: flex;
    border-radius: 2rem;
    justify-content: center;
    align-content: center;
    overflow-y: auto;
    flex-direction: column;
}

.circle1, .circle2{
    background: linear-gradient(to right bottom, rgba(255,255,255, 0.8), rgba(255, 255, 255, 0.2));
    height: 20rem;
    width: 20rem;
    position: absolute;
    border-radius: 50%;
    z-index: -1;
}


.circle1{
    top: 5%;
    right: 17%;
}

.circle2{
    bottom: 5%;
    left: 20%
}