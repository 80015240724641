.topmenu{
    background: linear-gradient(to right bottom, rgba(255,255,255, 1), rgba(255, 255, 255, 0.6));
    border-radius: 2rem 2rem 0 0;
    width: 100%;
    min-height: 10%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.topmenu img{
    max-height: 5vh;
    padding-left: 10px;
}